import React, { useState, useEffect } from "react";
import cartaodecreditoApi from "../../services/cartaodecredito.js";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import FaturaPorData from "./FaturaPorData.tsx";
import { Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import Loading from "../loading/loading.js";

const Faturas = (props) => {
  const params = useParams();
  const conta = props.conta;
  const [faturaSelecionada, setFaturaSelecionada] = useState({});
  const [numeroFaturaAtual, setNumeroFaturaAtual] = useState(0);
  const [radioValue, setRadioValue] = useState("");
  const [dataFatura, setDataFatura] = useState("");

  const {
    data: faturas,
    isLoading: loadingFaturas,
    isFetching: fetchingFaturas,
  } = useQuery<any>(
    "faturas_cartao",
    async () => {
      const url = "CartaoDeCredito/Faturas/" + conta;

      const response = await cartaodecreditoApi.get(url);
      const data = response.data;
      return data;
    },
    {
      enabled: !!conta && conta !== "undefined",
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 30,
    }
  );

  const SelecionarFatura = (fatura) => {
    setFaturaSelecionada(fatura);
    setDataFatura(fatura.dataDeVencimento);
    setRadioValue(fatura.dataDeVencimento);
  };

  const FormatDate = (stringDate) => {
    const dataString = stringDate.split("-");
    const year = dataString[0];
    const monthBill = dataString[1] - 1;
    var months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    return (
      <div>
        {months[monthBill]}
        <br></br>
        {year}
      </div>
    );
  };

  const FaturaSelecionada = (props) => {
    const { conta, DataDeVencimento } = props;

    return <FaturaPorData conta={conta} DataDeVencimento={DataDeVencimento} />;
  };

  useEffect(() => {
    if (faturas === null || faturas === undefined) {
      return;
    } else {
      if (faturas.length > 0) {
        setFaturaSelecionada(faturas[0]);
        setDataFatura(faturas[0].dataDeVencimento);
        setRadioValue(faturas[0].dataDeVencimento);
      }
    }
  }, [faturas]);

  return (
    <div className="container">
      {loadingFaturas || fetchingFaturas ? (
        <Loading />
      ) : (
        <div>
          {faturas.length === 0 ? (
            <div>
              <p>Não há faturas disponíveis para este cartão.</p>
            </div>
          ) : (
            <>
            <div className="">
              <ButtonGroup>
                {faturas.map((fatura, index) => (
                  <ToggleButton
                    id={`radio-${index}`}
                    variant="outline-dark"
                    type="radio"
                    className="btn-data"
                    key={index}
                    value={fatura.dataDeVencimento}
                    checked={fatura.dataDeVencimento === radioValue}
                    onClick={() => SelecionarFatura(fatura)}
                  >
                    {FormatDate(fatura.dataDeVencimento)}
                  </ToggleButton>
                ))}
              </ButtonGroup>
              <FaturaSelecionada conta={conta} DataDeVencimento={dataFatura} />
            </div>
            
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Faturas;
