import React, { useEffect, useState } from "react";
import cartaoApi from "../../services/cartaodecredito";
import { toast } from "react-toastify";
import "./RecebiMeuCartao.css";
import Portador from "../../models/portador";


const RecebiMeuCartao = (props) => {
  
  
  const [senha, setSenha] = useState("");
  const [confirmaSenha, setConfirmaSenha] = useState("");
  const [ultimos4Digitos, setUltimos4Digitos] = useState("");
  const [portador, setPortador] = useState<Portador>();
  const [senhasIguais, setSenhasIguais] = useState(true);
  
  
  
  useEffect(() => {
    if (props.portador) {
      setPortador(props.portador);
    }
  }, [props.portador]);

  

  const solicitarLiberacao = async (e) => {
    e.preventDefault();

    const body = {
      documento: portador?.cpf,
      novaSenha: senha,
      ultimosDigitos: ultimos4Digitos,
    };

    const retornoApi = await cartaoApi
      .post("/cartaodecredito/LiberarCartao", body)
      .catch((error) => {
        console.log(error);
      });

    
  };

  const ConfereSenhaIguais = () => {
    if (senha !== confirmaSenha) {
      setSenhasIguais(true);
      return "As senhas não são iguais";
      
    } else {
      setSenhasIguais(false);
      return "";
    }
  };

  function handleInput(e) {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  }
  

  return (
    <div className="container">
      <form className="form">
        <h1>Desbloquear Cartão</h1>
        <div className="input-group">
            <label className="label"><strong>Ultimos 4 digitos do cartão</strong></label>
          <input
            type="text"
            placeholder=""
            className="input"
            value={ultimos4Digitos}
            minLength={4}
            maxLength={4}
            onChange={(e) => setUltimos4Digitos(e.target.value)}
            pattern="[0-9]*"
            onInput={handleInput}
            required
          />
          
        </div>
        <div className="input-group">
        <label className="label"><strong>Defina sua senha</strong></label>
          <input
            type="password"
            placeholder=""
            className="input"
            value={senha}
            minLength={4}
            maxLength={4}
            pattern="[0-9]*"
            onInput={handleInput}
            onChange={(e) => setSenha(e.target.value)}
            required
          />
          
        </div>
        <div className="input-group">
        <label className="label"><strong>Confirme sua senha</strong></label>
          <input
            type="password"
            placeholder=""
            className="input"
            value={confirmaSenha}
            minLength={4}
            maxLength={4}
            pattern="[0-9]*"
            onInput={handleInput}
            onChange={(e) => setConfirmaSenha(e.target.value)}
            required
          />
          
        </div>
        <ConfereSenhaIguais />
        <div>
          <button type="submit" className="btn btn-secondary" onClick={solicitarLiberacao} disabled={senhasIguais}>
            Solicitar liberação
          </button>
        </div>
      </form>
    </div>
  );
};

export default RecebiMeuCartao;
