import React, { useState } from "react";
import cartaoApi from "../../services/cartaodecredito.js";
import "./CartaoDeCredito.css";
import visa from "../../assets/visa.svg";
import DesbloquearCartao from "./DesbloquearCartao.tsx";
import SaldoLimiteCartao from "./SaldoLimiteCartao.tsx";
import AlterarSenha from "./AlterarSenha.tsx";
import AlterarLimiteCartao from "./AlterarLimiteCartao.tsx";
const CartaoDeCredito = (params) => {
  const { cartao } = params;

  const [saldo, setSaldo] = useState(0);
  const [limite, setLimite] = useState(0);
  const limiteConta = params.limiteConta;
  const [componentRender, setComponentRender] = useState<React.ReactNode>(null);

  function onClickSenha() {
    setComponentRender(<AlterarSenha codigodocartao={cartao.codigodocartao} />);
  }

  function onClickLimite() {
    return setComponentRender(
      <AlterarLimiteCartao
        codigodocartao={cartao.codigodocartao}
        limiteAtual={limite}
        limiteConta={limiteConta}
      />
    );
  }

  return cartao?.status === "Bloqueado" ? (
    <></>
  ) : (
    <div>
      <div className="credit-card">
        <strong>{cartao?.nomeimpresso}</strong>
        <p>
          Final <strong> {cartao?.quatroultimosdigitos}</strong>
        </p>
        <p>
          <img src={visa} alt="visa" width="36px"></img>
        </p>
        {cartao?.statusdocartao === "Normal" ? null : (
          <p>{cartao?.statusdocartao}</p>
        )}

        {cartao?.statusdocartao !== "Cancelado" ? (
          <div>
            <SaldoLimiteCartao
              codigodocartao={cartao?.codigodocartao}
              setSaldo={setSaldo}
              setLimite={setLimite}
            />
            <br></br>
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Opções
              </button>
              <ul className="dropdown-menu">
                {/**
                <li>
                  <DesbloquearCartao
                    codigodocartao={cartao?.codigodocartao}
                    statusdocartao={cartao?.statusdocartao}
                  />
                </li>
                 */}
                {cartao?.statusdocartao !== "Bloqueado" ? (
                  <li>
                    <button className="dropdown-item" onClick={onClickSenha}>
                      Alterar senha
                    </button>
                  </li>
                ) : null}
                <li>
                  <button className="dropdown-item" onClick={onClickLimite}>
                    Alterar limite
                  </button>
                </li>
              </ul>
            </div>
            <div className="container-fluid">
            {componentRender}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CartaoDeCredito;
