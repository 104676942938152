import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import cartaoApi from "../../services/cartaodecredito";
import { toast } from "react-toastify";

const DesbloquearCartao = (props) => {
  const [code, setCode] = useState("");
  const codigodocartao = props.codigodocartao;
  const statusdocartao = props.statusdocartao;
  const navigate = useNavigate();

  const desbloquearCartao = async () => {
    try {
      const produtoSolicitado = await cartaoApi.post(
        "/cartaodecredito/desbloquearCartao/" + codigodocartao,
        {}
      );

      const statusUpdateProduct =
        produtoSolicitado.status === 200 ? "success" : "error";

      if (statusUpdateProduct === "success") {
        toast.success("Solicitacao realizada com sucesso!");
        navigate("/dashboard/home");
      } else {
        toast.error(
          "Não foi possível realizar o desbloqueio do cartão! Tente novamente em instantes."
        );
        toast.error("Erro: " + produtoSolicitado.data.message);
      }
    } catch (error) {
      toast.error(
        "Não foi possível realizar o desbloqueio do cartão! Tente novamente em instantes."
      );
    }
  };

  const bloquearCartao = async () => {
    try {
      const produtoSolicitado = await cartaoApi.post(
        "/CartaoDeCredito/Bloquear/" + codigodocartao,
        {}
      );

      const statusUpdateProduct =
        produtoSolicitado.status === 200 ? "success" : "error";

      if (statusUpdateProduct === "success") {
        toast.success("Solicitacao realizada com sucesso!");
        navigate("/dashboard/home");
      } else {
        toast.error(
          "Não foi possível realizar o desbloqueio do cartão! Tente novamente em instantes."
        );
        toast.error("Erro: " + produtoSolicitado.data.message);
      }
    } catch (error) {
      toast.error(
        "Não foi possível realizar o desbloqueio do cartão! Tente novamente em instantes."
      );
    }
  };

  const OptButton = (props) => {
    const statusdocartao = props.statusdocartao;
    if (statusdocartao === "Bloqueado") {
      return (
        <button
          type="button"
          className="dropdown-item"
          onClick={desbloquearCartao}
        >
          Desbloquear
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="dropdown-item"
          onClick={bloquearCartao}
        >
          Bloquear
        </button>
      );
    }
  };

  return <OptButton statusdocartao={statusdocartao} />;
};

export default DesbloquearCartao;
