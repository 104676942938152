import React, { useEffect, useState } from "react";
import "./dashboard.css";
import Navbardashboard from "../dashboard/navbar";
import NavRendPay from "../navrendpay/navrendpay.js";
import cartaoApi from "../../services/cartaodecredito.js";
import CartaoDeCredito from "../cartaoDeCredito/CartaoDeCredito.tsx";
import { useQuery } from "react-query";
import customerApi from "../../services/CustomersApi";
import { USER_ID, CUSTOMER_ID, logout } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import AcompanharSolicitacao from "../cartaoDeCredito/AcompanharSolicitacao.tsx";
import Loading from "../loading/loading";
import SaldoLimiteConta from "../cartaoDeCredito/SaldoLimiteConta.tsx";

const Dashboard = () => {
  const navigate = useNavigate();
  const [titulo, setTitulo] = useState("");
  const customerId = localStorage.getItem(CUSTOMER_ID);

  const [componentRender, setComponentRender] = useState();
  const [limiteConta, setLimiteConta] = useState(0);

  const alterarTituloPagina = (titulo) => {
    setTitulo(titulo);
  };

  const doLogout = () => {
    logout(navigate);
  };

  const fetchCliente = async () => {
    const userId = localStorage.getItem(USER_ID);
    const response = await customerApi.get("customers/userid/" + userId);

    localStorage.setItem(CUSTOMER_ID, response.data.id);
    return response.data;
  };

  const fetchPortador = async () => {
    if (customerId == null || customerId == undefined || customerId == "") {
      return null;
    } else {
      const response = await customerApi.get(
        "customers/portador/" + customerId
      );
      return response.data;
    }
  };

  const fetchCartoes = async () => {
    if (customerPortador == null) {
      return [];
    }
    if (
      customerPortador?.cpf === "" ||
      customerPortador?.cpf === null ||
      customerPortador?.cpf === undefined
    ) {
      return [];
    }
    const url = "CartaoDeCredito/ListaCartoes/" + customerPortador?.cpf;

    try {
      const response = await cartaoApi.get(url);
      var data = response.data;

      return data;
    } catch (e) {
      return [];
    }
  };

  const fetchFaturaFechada = async () => {
    const url = "CartaoDeCredito/FaturaFechada/" + cartoes[0]?.codigodaconta;

    try {
      const response = await cartaoApi.get(url);
      var data = response.data.conteudo.Fatura;

      return data;
    } catch (e) {
      return [];
    }
  };

  const {
    data: customer,
    isLoading: loadingCliente,
    isFetching: fetchingCliente,
    error: ErrorCliente,
  } = useQuery("user_customer", fetchCliente, {
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 30,
  });

  const {
    data: customerPortador,
    isLoading: loadingPortador,
    isFetching: fetchingPortador,
    error: ErrorPortador,
  } = useQuery("customer_portador", fetchPortador, {
    enabled: !!customer && customer?.id !== undefined && customer?.id !== null,
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 30,
  });

  const {
    isLoading: loadingCartoes,
    data: cartoes,
    isFetching: fetchingCartoes,
    error: ErrorListarCartoes,
  } = useQuery("meus_cartoes", fetchCartoes, {
    enabled:
      !!customerPortador &&
      customerPortador?.cpf !== undefined &&
      customerPortador?.cpf !== null,
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 60,
  });

  const {
    isLoading: loadingFaturaFechada,
    data: faturaFechada,
    isFetching: fetchingFaturaFechada,
    error: ErrorListarFaturaFechada,
  } = useQuery("fatura_fechada", fetchFaturaFechada, {
    enabled:
      !!cartoes &&
      !!customerPortador &&
      customerPortador?.cpf !== undefined &&
      customerPortador?.cpf !== null,
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 60,
  });

  if (
    loadingCliente &&
    loadingPortador &&
    loadingCartoes &&
    loadingFaturaFechada &&
    fetchingCliente &&
    fetchingPortador &&
    fetchingCartoes &&
    fetchingFaturaFechada
  ) {
    return (
      <div>
        <NavRendPay />
        <Loading />
      </div>
    );
  } else {
    if (cartoes) {
      if (cartoes.length === 0) {
        return (
          <div>
            <AcompanharSolicitacao
              customerId={customerId}
              numeroDocumento={customerPortador?.cpf}
            ></AcompanharSolicitacao>
          </div>
        );
      } else {
        return (
          <div>
            <NavRendPay />
            <SaldoLimiteConta
              conta={cartoes[0].codigodaconta}
              setLimiteConta={setLimiteConta}
            />

            <div className="dashboard">
              <div className="navdash">
                <Navbardashboard
                  conta={cartoes[0].codigodaconta}
                  cartoes={cartoes}
                  customerId={customerId}
                  portador={customerPortador}
                  titulo={titulo}
                  alterarTitulo={setTitulo}
                  setComponentRender={setComponentRender}
                  limiteConta={limiteConta}
                />
               
              </div>
              <div class="component-container">{componentRender}</div>
            </div>
          </div>
        );
      }
    }
  }
};

export default Dashboard;
