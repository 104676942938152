import React, { useState } from "react";
import wg from "../../assets/wg.png";
import "./register.css";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import authenticationApi from "../../services/authenticationApi.js";
import NavRendPay from "../navrendpay/navrendpay.js";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");

  const location = useNavigate();

  const actualLocation = useLocation();
  const convite = new URLSearchParams(actualLocation.search).get("convite");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setErrorMessage("As senhas não são iguais");
      toast.error(
        "As senhas não são iguais! Por favor verifiquei e tente novamente."
      );
    } else {
      SendUserApi(name, email, password, convite);
      setErrorMessage(""); // Limpar a mensagem de erro
    }
  };

  const onPasswordChange = (e) => {
    const valorDigitado = e.target.value;
    setPassword(valorDigitado);

    const strength = checkPasswordStrength(password);
    setPasswordMessage(strength);
  };

  const onConfirmPasswordChange = (e) => {
    const confirmacaoDeSenha = e.target.value;
    setConfirmPassword(confirmacaoDeSenha);
  };

  const ConfereSenhaIguais = () => {
    if (password !== confirmPassword) {
      return "As senhas não são iguais";
    } else {
      return "";
    }
  };

  let btnViewSenha = document.querySelector('#eye');
  if (btnViewSenha) {
    let input = document.querySelector("#password");

    btnViewSenha.addEventListener("mousedown", function () {
      input.setAttribute("type", "text")
    });
    btnViewSenha.addEventListener("mouseup", function () {
      input.setAttribute("type", "password");
    });
    btnViewSenha.addEventListener("mousemove", function () {
      input.setAttribute("type", "password");
    });
  }

  let btnViewConfSenha = document.querySelector('#eyeconf');
  if (btnViewConfSenha) {
    let input = document.querySelector("#confirmPassword");

    btnViewConfSenha.addEventListener("mousedown", function () {
      input.setAttribute("type", "text")
    });
    btnViewConfSenha.addEventListener("mouseup", function () {
      input.setAttribute("type", "password");
    });
    btnViewConfSenha.addEventListener("mousemove", function () {
      input.setAttribute("type", "password");
    });
  }

  const SendUserApi = async (name, email, password, convite) => {
    let data = JSON.stringify({
      Name: name,
      Email: email,
      Password: password,
      Convite: convite,
    });

    const retornoApi = await authenticationApi
      .post("/register", data)
      .catch((error) => {
        if (error.response) {
          toast.error("Convite invalido!");
        }
      });

    const statusApi = retornoApi.status;

    if (statusApi === 200) {
      toast.success("Usuário cadastrado com sucesso!");
      location("/login");
    } else {
      toast.error("Erro ao cadastrar usuário!");
    }
  };

  function checkPasswordStrength(password) {
    // Initialize variables
    var strength = 0;
    var tips = [];
    var forca = "";

    // Check password length
    if (password.length < 8) {
      tips.push("A senha precisa ter ao menos 8 digitos. ");
    } else {
      strength += 1;
    }

    // Check for mixed case
    if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
      strength += 1;
    } else {
      tips.push("Necessário letras maísculas e minusculas. ");
    }

    // Check for numbers
    if (password.match(/\d/)) {
      strength += 1;
    } else {
      tips.push("Inclua ao menos um numero. ");
    }

    // Check for special characters
    if (password.match(/[^a-zA-Z\d]/)) {
      strength += 1;
    } else {
      tips.push("Inclua ao menos um caracter especial. ");
    }

    // Return results
    if (strength < 2) {
      forca = "Fraca";
    } else if (strength === 2) {
      forca = "Média";
    } else if (strength === 3) {
      forca = "Forte";
    } else {
      forca = "Muito Forte";
    }
    return (
      <div>
        {forca}{" "}
        <ul>
          {tips.map((tip, index) => {
            return <li key={index}>{tip}</li>;
          })}
        </ul>
      </div>
    );
  }

  return (
    <div className="login">
      <NavRendPay />

      <div className="full">
        <div className="login-div">
          <form
            className="form"
            data-bitwarden-watching="1"
            onSubmit={handleSubmit}
          >
            <h2>Cadastre-se</h2>
            <div className="input-group">
              <label htmlFor="name" className="label">
                {" "}
                Nome
              </label>
              <input
                id="name"
                name="name"
                type="text"
                className="input"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nome"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="email" className="label">
                {" "}
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                className="input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
            </div>
            <div className="input-group">
              <label htmlFor="password" className="label">
                Senha
              </label>
              <input
                type="password"
                placeholder="Senha"
                className="input"
                id="password"
                name="password"
                value={password}
                onChange={(e) => onPasswordChange(e)}
                required
              />
              <span className="lnr lnr-eye" id="eye"></span>
            </div>
            {passwordMessage && <div>{passwordMessage}</div>}
            <div className="input-group">
              <label htmlFor="password" className="label">Confirme a senha</label>
              <input
                type="password"
                placeholder="Confirme a senha"
                id="confirmPassword"
                name="confirmPassword"
                className="input"
                value={confirmPassword}
                onChange={(e) => onConfirmPasswordChange(e)}
                required
              />
              <span className="lnr lnr-eye" id="eyeconf"></span>
            </div>
            <div>
              {errorMessage && (
                <p className="mt-6 text-red-500">{errorMessage}</p>
              )}
              {<ConfereSenhaIguais />}
            </div>
            <button type="submit" className="btn btn-primary">
              Cadastrar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
