import React, { useState, useEffect } from "react";
import cartaoApi from "../../services/cartaodecredito.js";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

const UltimasTransacoes = (props) => {
  const [faturas, setFaturas] = useState([]);
  const conta = props.conta;
  const onClickDesbloq = props.onClickDesbloq;

  const { data: ultimasTransacoes, isFetching: fetchingUltimasTransacoes } =
    useQuery<any>(
      "ultimasTransacoes",
      async () => {
        const url = "cartaodecredito/Transacoes/" + conta;
        const result = await cartaoApi.get(url);
        const data = result.data;
        return data;
      },
      {
        enabled: !!conta && conta !== "undefined",
        refetchOnWindowFocus: false,
        cacheTime: 1000 * 60 * 30,
      }
    );
    const FormatDate = (stringDate) => {
      const dataString = stringDate.split("/");
      console.log(dataString);
      const day = dataString[0];
      const monthBill = dataString[1] - 1;
      var months = [
        "JAN",
        "FEV",
        "MAR",
        "ABR",
        "MAI",
        "JUN",
        "JUL",
        "AGO",
        "SET",
        "OUT",
        "NOV",
        "DEZ",
      ];
  
      const dateReturn = day+"/"+months[monthBill];
  
      return dateReturn;
        
      
    };
  return  (
    <div>
      <div>
        <button className="btn btn-secondary" onClick={onClickDesbloq}>
          Desbloquear um cartão
        </button>
      </div>
      {ultimasTransacoes ? (<ul>
          {ultimasTransacoes?.map((lancamento, index) => (
            <li>
              <div key={index}  className="linhaLancamento">
              <div className="linhaLancamento-data">{FormatDate(lancamento?.dataDaCompra.split(" ")[0])}</div>
              <div className="linhaLancamento-descricao">{lancamento?.nomeFantasiaDoEstabelecimento}
              {lancamento.numeroDaParcela == 0 ? (
                  "1 / 1"
                ) : (
                  <>
                    {lancamento.numeroDaParcela} /{" "}
                    {lancamento.quantidadeDeParcelas}
                  </>
                )}
              </div>
              <div className="linhaLancamento-valor">
              {lancamento.valor.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </div>
              <div>
              {lancamento?.status === 0 ? "Normal" : null}
                {lancamento?.status === 1 ? "Cancelada" : null}
                {lancamento?.status === 2 ? "Processada" : null}
                {lancamento?.status === 3 ? "Pendente" : null}
                {lancamento?.status === 4 ? "Desfeita" : null}
                {lancamento?.status === 5 ? "Estornada" : null}
                {lancamento?.status === 6 ? "Baixada por prazo" : null}
              </div>
            </div>              
            </li>
          ))}
      </ul>): <>Nenhuma transação</>}
      
      </div>
  );
};

export default UltimasTransacoes;
