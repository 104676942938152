import React from "react";
import "./AlterarLimiteCartao.css";
import { useState } from "react";
import cartaoApi from "../../services/cartaodecredito.js";
import { toast } from "react-toastify";

const AlterarLimiteCartao = (props) => {
  const codigoCartao = props.codigodocartao;
  const limiteAtual = props.limiteAtual;
  const limiteConta = props.limiteConta;
  

  const [novoLimite, setNovoLimite] = useState(limiteAtual);

  const handleSubmit = (event) => {
    event.preventDefault();

    const endPoint = "cartaodecredito/AlterarLimiteCartao/" + codigoCartao;

    const body = { novoLimite: novoLimite };

    cartaoApi
      .post(endPoint, body)
      .then((response) => {
        console.log(response);
        toast.success("Limite alterado com sucesso!");
        window.location.reload();

      })
      .catch((error) => {
        console.log(error);
        toast.success("Não foi possível alterar o limite!");
      });
  };

  return (
    <div className="">
      
      <form className="form form-limite">
        <div className="">
          <p>Limite do cartão</p>
          <div>
            <span>R$</span>
          <input
            type="number"
            className="limiteInput"
            id="limiteControl"
            value={novoLimite}
            min={0}
            max={1000}
            onChange={(e) => setNovoLimite(e.target.value)}    
          />
          </div>
          <br></br>
          
        
          <input
            type="range"
            className="limit-slider"
            id="limite"
            placeholder="Digite o novo limite"
            value={novoLimite}
            min={0}
            max={limiteConta}
            step={10}
            onChange={(e) => setNovoLimite(e.target.value)}
          />
        </div>
        <br></br>
        <button type="submit" className="btn btn-secondary " onClick={handleSubmit}>
          Alterar
        </button>
      </form>
    </div>
  );
};

export default AlterarLimiteCartao;
