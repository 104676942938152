import React, { useState } from "react";
import CartaoDeCredito from "./CartaoDeCredito.tsx";
import "./Cartoes.css";

const Cartoes = (props) => {
  const cartoes = props.cartoes;
  const limiteConta = props.limiteConta;

  const [titulo, setTitulo] = useState("");
  //const [componentRender, setComponentRender] = useState();

  return (
    <div className="cartoes">
      <ul>
        {cartoes.map((cartao, index) => (
          <li key={index}>
            <CartaoDeCredito
              cartao={cartao}
              limiteConta={limiteConta}
            />
          </li>
        ))}
      </ul>
    
    </div>
  );
};

export default Cartoes;
