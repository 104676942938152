import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, login } from "../../services/auth";
import { toast } from "react-toastify";

import "./login.css";

import NavRendPay from "../navrendpay/navrendpay";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [logged, setLogged] = useState(false);
  const [btnAvancarDisabled, setbtnAvancarDisabled] = useState(true);
  const [btnDisabled, setbtnDisabled] = useState(true);
  const [recordUser, setRecordUser] = useState(true);
  const [step, setStep] = useState(1);



  const nextStep = () => {
    setStep(step + 1);
  };

  const previousStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const logou = await login(email, password);
    if (logou) {
      toast.success(
        "Login efetuado com sucesso! Um codigo de validação foi enviado para o seu email."
      );
      if (recordUser) {
        localStorage.setItem("user", email);
      } else {
        localStorage.removeItem("user");
      }

      navigate("/validaLogin", { state: { email: email } });
    } else {
      toast.error("Usuario e/ou senha invalidos!");
      setStep(1);
    }
  };

  const updEmailSenha = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      if (e.target.value.length > 0) {
        setbtnAvancarDisabled(false);
      } else {
        setbtnAvancarDisabled(true);
      }
    } else {
      setPassword(e.target.value);
      if (e.target.value.length > 0) {
        setbtnDisabled(false);
      } else {
        setbtnDisabled(true);
      }
    }
  };

  const handleEnableButton = () => {
    if (email.length > 0 && password.length > 0) {
      setbtnDisabled(false);
    } else {
      setbtnDisabled(true);
    }
  };

  let btnViewSenha = document.querySelector('#eye');
  if (btnViewSenha) {
    let input = document.querySelector("#password");

    btnViewSenha.addEventListener("mousedown", function () {
      input.setAttribute("type", "text")
    });
    btnViewSenha.addEventListener("mouseup", function () {
      input.setAttribute("type", "password");
    });
    btnViewSenha.addEventListener("mousemove", function () {
      input.setAttribute("type", "password");
    });
  }

  useEffect(() => {
    const savedUser = localStorage.getItem("user");
    if (savedUser && recordUser && email.length < 1) {
      setEmail(savedUser);
      console.log("savedUser", savedUser);
      setRecordUser(true);
    }
    setLogged(isAuthenticated());
    if (logged) {
      navigate("/dashboard/home");
    }
  }, [
    logged,
    navigate,
    email,
    password,
    recordUser,
    btnDisabled,
    btnAvancarDisabled,
  ]);

  return (
    <div>
      <NavRendPay />
      <div className="full">
        <div className="login-div">
          <form className="form">
            <div className="welcome-div w100">
              <span className="">Bem vindo ao</span>
              <div>
                <span className="gray-text">Cartão de </span>
                <span className="corporativo-text"> Crédito</span>
              </div>
            </div>
            {step === 1 && (
              <div className="w100">
                <div className="input-group">
                  <label htmlFor="floatingInput" className="label">
                    <strong>E-mail</strong>
                  </label>
                  <input
                    type="email"
                    id="floatingInput"
                    className="input"
                    name="email"
                    placeholder="Digite seu e-mail"
                    value={email}
                    onInput={(e) => {
                      updEmailSenha(e);
                    }}
                    required
                  />
                </div>
                <div className="record-user-div w100">
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked={recordUser}
                      onChange={() => setRecordUser(!recordUser)}
                    />
                    <span class="slider round"></span>
                  </label>
                  <label className="label record-user">Lembrar usuário</label>
                </div>
                <button
                  onClick={nextStep}
                  className="btn btn-primary w100"
                  disabled={btnAvancarDisabled}
                >
                  Avançar
                </button>
              </div>
            )}

            {step === 2 && (
              <div className="w100">
                <div className="input-group">
                  <label htmlFor="floatingPassword" className="label">
                    <strong>Password</strong>
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="input"
                    name="password"
                    minLength={8}
                    placeholder="Digite sua senha"
                    value={password}
                    onInput={(e) => {
                      updEmailSenha(e);
                    }}
                    required
                  />
                  <span className="lnr lnr-eye" id="eye"></span>
                </div>
                <div className="record-user-div w100"></div>

                <button
                  className="btn btn-primary w100"
                  onClick={handleSubmit}
                  disabled={btnDisabled}
                >
                  Entrar
                </button>
                <button
                  onClick={previousStep}
                  className="btn btn-secondary w100"
                  Style="margin-top: 16px;"
                >
                  Voltar
                </button>
              </div>
            )}
            {step === 1 && (
              <div className="first-access">
                <div>
                  <span className="label">Primeiro acesso? </span>
                  <Link to="/register" className="primary-color">
                    Cadastre-se
                  </Link>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
