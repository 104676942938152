import React from "react";
import cartaoApi from "../../services/cartaodecredito.js";
import { useState } from "react";
import { useQuery } from "react-query";
import Loading from "../loading/loading.js";

const SaldoLimiteCartao = (props) => {
  const codigodocartao = props.codigodocartao;
  const setSaldo = props.setSaldo;
  const setLimite = props.setLimite;
  
  const { data: saldoLimite, isFetching: fetchingSaldoLimite } = useQuery<any>(
    "saldoLimite",
    async () => {
      const url = "cartaodecredito/LimiteCartao/" + codigodocartao;
      const result = await cartaoApi.get(url);
      const data = result.data.conteudo;
      setSaldo(data.saldoDisponivelMensal);
      setLimite(data.limiteMensal);
      return data;
    },
    {
      enabled: !!codigodocartao && codigodocartao !== "undefined",
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 30,
    }
  );

  return fetchingSaldoLimite ? (
    <Loading />
  ) : (
    	
    <div className="container">
      <div className="row">
      <div className="col">
        <div className="input-group">
        <strong className="label">Limite disponivel</strong>
        <input className="input" value={saldoLimite?.saldoDisponivelMensal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} disabled/> 
        </div>
        
      </div>
      <div className="col">
        <div className="input-group">
        <strong className="label">Limite </strong> <input value={saldoLimite?.limiteMensal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} className="input" disabled/>
        </div>
        
      </div>
      </div>
    </div>
  );
};

export default SaldoLimiteCartao;
