import React, { useState, useEffect } from "react";
import cartaoApi from "../../services/cartaodecredito.js";
import { useQuery } from "react-query";
import Loading from "../loading/loading.js";
import "./FaturaPorData.css";
import { toast } from "react-toastify";

const FaturaPorData = (params) => {
  const { conta, DataDeVencimento } = params;

  const { data: fatura, isFetching: fetchingFatura } = useQuery<any>(
    "detalhes_faturas_cartao_" + DataDeVencimento,
    async () => {
      const url =
        "CartaoDeCredito/Faturas/DetalheFaturaVencimento/" +
        conta +
        "/" +
        DataDeVencimento;

      const result = await cartaoApi.get(url);
      const data = result.data.conteudo;

      return data;
    },
    {
      enabled:
        !!conta &&
        conta !== "undefined" &&
        !!DataDeVencimento &&
        DataDeVencimento !== "undefined",
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 30,
    }
  );

  const copiarCodigoBarras = async () => {
    var copyText = document.getElementById("linhaDigitavel");
    const value = copyText?.textContent ?? "";
    await navigator.clipboard.writeText(value);
    toast.success("Código de barras copiado!");
  };
  const FormatDate = (stringDate) => {
    const dataString = stringDate.split("/");
    console.log(dataString);
    const day = dataString[0];
    const monthBill = dataString[1] - 1;
    var months = [
      "JAN",
      "FEV",
      "MAR",
      "ABR",
      "MAI",
      "JUN",
      "JUL",
      "AGO",
      "SET",
      "OUT",
      "NOV",
      "DEZ",
    ];

    const dateReturn = day+"/"+months[monthBill];

    return dateReturn;
      
    
  };

  const HeaderFatura = (params) => {
    const { fatura } = params;
    const dataDividida = DataDeVencimento.split("-");
    const dataExibir =
      dataDividida[2] + "/" + dataDividida[1] + "/" + dataDividida[0];
    return (
      <div className="container">
        <br></br>
        <div className="campos-fatura form">
          <div className="">
            <div className="input-group">
              <label className="label">Total</label>
              <input
                type="number"
                value={fatura?.valorTotal}
                className="input"
              ></input>
            </div>
          </div>
          <div className="">
            <div className="input-group">
              <label className="label">Pagamento Mínimo</label>
              <input
                type="number"
                value={fatura?.valorPagamentoMinimo}
                className="input"
                disabled
              ></input>
            </div>
          </div>
          <div className="">
            <div className="input-group">
              <label className="label">Saldo Anterior</label>
              <input
                type="number"
                value={fatura?.saldoAnterior}
                className="input"
                disabled
              ></input>
            </div>
          </div>
          <div className="">
            <div className="input-group">
              <label className="label">Vencimento</label>
              <input
                type="text"
                value={dataExibir}
                className="input"
                disabled
              ></input>
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col">
            {fatura?.pagamentoEfetuado === "False" ? (
              <p>Em aberto</p>
            ) : (
              <p>Pago</p>
            )}

            {fatura?.pagamentoEfetuado === "False" && fatura?.linhaDigitavel ? (
              <div>
                <p>Codigo para pagamento</p>
                <div className="input-group">
                  <input
                    type="text"
                    className="input"
                    id="linhaDigitavel"
                    value={fatura?.linhaDigitavel}
                  />
                  <span
                    className="lnr lnr-book"
                    id="eyeconf"
                    onClick={copiarCodigoBarras}
                  ></span>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  };

  const TransasoesFatura = (params) => {
    const { Lancamentos } = params;
    if (Lancamentos == null) {
      return null;
    }
    return (
      <div>
        <HeaderFatura fatura={fatura} />
        <div>
          {Lancamentos?.map((lancamento, index) => (
            <div key={index}  className="linhaLancamento">
              <div className="linhaLancamento-data">{FormatDate(lancamento.dataDaOperacao.split(" ")[0])}</div>
              <div className="linhaLancamento-descricao">{lancamento.descricaoDoEstabelecimento}
              {lancamento.numeroDaParcela == 0 ? (
                  "1 / 1"
                ) : (
                  <>
                    {lancamento.numeroDaParcela} /{" "}
                    {lancamento.quantidadeDeParcelas}
                  </>
                )}
              </div>
              <div className="linhaLancamento-valor">
              {lancamento.valorDaOperacao.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="container">
        {fetchingFatura ? (
          <Loading />
        ) : (
          <TransasoesFatura Lancamentos={fatura?.lancamentos} />
        )}
      </div>
      <br></br>
    </div>
  );
};

export default FaturaPorData;
