import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { validarCodigoAutenticacao } from "../../services/auth";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import NavRendPay from "../navrendpay/navrendpay";
import wg from "../../assets/wg.png";
import iconValid from "../../assets/validaCodigo.svg";
import "./validaLogin.css";
import { Form } from "react-bootstrap";

const ValidaLogin = () => {
  const { state } = useLocation();
  const email = state.email;
  const [codigo, setCodigo] = useState("");
  const [camposCodigo, setCamposCodigo] = useState({"0":"","1":"","2":"","3":"","4":"","5":""});

  const navigate = useNavigate();

  const handleSubmit = async () => {
    //e.preventDefault();

    
    

    if (await validarCodigoAutenticacao(email, codigo)) {
      toast.success("Código validado com sucesso!");
      navigate("/dashboard/home");
    } else {
      toast.error("Código inválido!");
      navigate("/login");
    }
  };

  const handleInputChange = (e, nextInput) => {
    const { name, value } = e.target;

    if (e.target.value.length === 1 && nextInput) {
      setCamposCodigo({ ...camposCodigo, [name]: value });
      
      if (name !== "5") {
        nextInput.current.focus();
      }
    }

    
    const newCodigo = Object.values(camposCodigo).join("")+value;
    
    setCodigo(newCodigo);
    
    if(codigo.length === 6) {
      handleSubmit();
    }

  };

  function handleKeyDown(e, previousInputRef) {
    if (e.key === 'Backspace' && e.target.value === '' && previousInputRef) {
      previousInputRef.current.focus();
      const name = previousInputRef.current.name;
      setCamposCodigo(prevState => ({ ...prevState, [name]: '' }));
    }
  }
  

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text'); 

    setCodigo(pastedData);

    setCamposCodigo({
      "0": pastedData[0],
      "1": pastedData[1],
      "2": pastedData[2],
      "3": pastedData[3],
      "4": pastedData[4],
      "5": pastedData[5],
    });
    
  };
  
  useEffect(() => {
    
    if (codigo.length === 6) {
      handleSubmit();
    }
  }, [camposCodigo, codigo]);

  const inputRefs = Array(7).fill(0).map(() => React.createRef<HTMLInputElement>());

  return (
    <>
      <NavRendPay />
      <div className="valid-div">
        <form className="form" data-bitwarden-watching="1">
          <div className="msg">
            <img
              src={iconValid}
              alt="icon"
              className="icon"
              height={57}
              width={57}
            />
            <div className="confira-o-codigo w100">
              <span className="confira">Confira o código</span>
              <p className="enviado">
                enviado <span className="sms">por E-MAIL!</span>
              </p>
            </div>
          </div>
          <div className="digite-codigo w100">
            <span>Digite o código que enviamos por email.</span>
          </div>
          <div className="codigo-div">
            <input
              type="text"
              maxLength={1}
              className="input-codigo"
              name="0"
              value={camposCodigo["0"].toString()}
              ref={inputRefs[0]}
              onInput={(e) => handleInputChange(e, inputRefs[1])}
              onKeyDown={(e) => handleKeyDown(e, null)}
              onPaste={handlePaste}
            />
            <input
              type="text"
              maxLength={1}
              className="input-codigo"
              name="1"
              value={camposCodigo["1"].toString()} // Change the type of camposCodigo["1"] to string
              ref={inputRefs[1]}
              onInput={(e) => handleInputChange(e, inputRefs[2])}
              onKeyDown={(e) => handleKeyDown(e, inputRefs[0])}
              onPaste={handlePaste}
            />
            <input
              type="text"
              maxLength={1}
              className="input-codigo"
              name="2"
              value={camposCodigo["2"].toString()}
              ref={inputRefs[2]}
              onInput={(e) => handleInputChange(e, inputRefs[3])}
              onKeyDown={(e) => handleKeyDown(e, inputRefs[1])}
              onPaste={handlePaste}
            />
            <span className="traco">-</span>
            <input
              type="text"
              maxLength={1}
              className="input-codigo"
              name="3"
              value={camposCodigo["3"].toString()}
              ref={inputRefs[3]}
              onInput={(e) => handleInputChange(e, inputRefs[4])}
              onKeyDown={(e) => handleKeyDown(e, inputRefs[2])}
              onPaste={handlePaste}
              
            />
            <input
              type="text"
              maxLength={1}
              className="input-codigo"
              name="4"
              value={camposCodigo["4"].toString()}
              ref={inputRefs[4]}
              onInput={(e) => handleInputChange(e, inputRefs[5])}
              onKeyDown={(e) => handleKeyDown(e, inputRefs[3])}
              onPaste={handlePaste}
            />
            <input
              type="text"
              maxLength={1}
              className="input-codigo"
              name="5"
              value={camposCodigo["5"].toString()}
              ref={inputRefs[5]}
              onInput={(e) => handleInputChange(e, inputRefs[6])}
              onKeyDown={(e) => handleKeyDown(e, inputRefs[4])}
              onPaste={handlePaste}
            />
          </div>
          <div>
            <span>
              Reenviar o <a href="/login">código</a>
            </span>
          </div>
        </form>
      </div>
    </>
  );
};

export default ValidaLogin;
